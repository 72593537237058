<template>
  <v-dialog v-model="visible" persistent max-width="700">
    <v-card class="pa-5 rounded-lg" id="card-custom">
      <v-btn @click="visible = false" x-small icon class="float-right">
        <v-icon>close</v-icon>
      </v-btn>
      <v-card-text class="subtitle-2 pt-0 font-weight-bold">
        Mutasi Pendidikan Pegawai
      </v-card-text>
      <v-divider class="mb-5" />
      <v-form
        id="form"
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="save()"
      >
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >NIP</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-text-field
              v-model="detail.NIP"
              hide-details
              disabled
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >Nama</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-text-field
              v-model="detail.nama"
              hide-details
              disabled
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card outlined class="pa-5 mt-2">
          <p class="caption pt-0 font-weight-bold">Pendidikan Baru</p>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Pendidikan</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-text-field
                :value="detail.jenjang && detail.jenjang.nama_pend"
                hide-details
                disabled
                outlined
                dense
              ></v-text-field>
              <v-autocomplete
                class="mt-1"
                :items="pendidikanList"
                :loading="loading_pendidikan"
                outlined
                dense
                v-model="form.jenjang"
                item-text="nama_pend"
                item-value="kode_pend"
                return-object
                :rules="[v => !!v || 'Program studi harus diisi']"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Pendidikan</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-text-field
                :value="detail.bidang_studi && detail.bidang_studi.nama_studi"
                hide-details
                disabled
                outlined
                dense
              ></v-text-field>
              <v-autocomplete
                class="mt-1"
                :items="studiList"
                :loading="loading_studi"
                outlined
                dense
                v-model="form.bidang_studi"
                item-text="nama_studi"
                item-value="kode_studi"
                return-object
                :rules="[v => !!v || 'Program studi harus diisi']"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Nama Sekolah</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-text-field
                v-model="detail.nama_sekolah"
                hide-details
                disabled
                outlined
                dense
              ></v-text-field>
              <v-text-field
                class="mt-1"
                v-model="form.nama_sekolah"
                outlined
                dense
                :rules="[v => !!v || 'Nama sekolah harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Tahun Lulus</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-text-field
                v-model="detail.thn_lulus"
                hide-details
                disabled
                outlined
                dense
              ></v-text-field>
              <v-text-field
                class="mt-1"
                v-model="form.thn_lulus"
                outlined
                dense
                :rules="[v => !!v || 'Tahun lulus harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <v-card-actions class="mt-3">
          <v-spacer />
          <v-btn
            small
            outlined
            color="#717171"
            class="px-12"
            @click="visible = false"
            >Batal</v-btn
          >
          <v-btn
            small
            color="#FBB005"
            class="px-12 subtitle-2 text-capitalize"
            :loading="formLoading"
            type="submit"
            :disabled="!valid"
            form="form"
            >Simpan</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import PublicService from "@/services/resources/public.service";

export default {
  data() {
    return {
      valid: false,
      visible: false,
      formLoading: false,
      loading_pendidikan: false,
      loading_studi: false,
      pendidikanList: [],
      studiList: [],
      detail: {
        NIP: null,
        nama: null,
        jenjang: null,
        bidang_studi: null,
        nama_sekolah: null,
        thn_lulus: null
      },
      form: {
        jenjang: null,
        bidang_studi: null,
        nama_sekolah: null,
        thn_lulus: null
      }
    };
  },
  methods: {
    change(val) {
      this.visible = val;
    },
    populate(item) {
      this.detail.NIP = item.NIP;
      this.detail.nama = item.nama;
      this.detail.jenjang = item.jenjang;
      this.detail.bidang_studi = item.bidang_studi;
      this.detail.nama_sekolah = item.nama_sekolah;
      this.detail.thn_lulus = item.thn_lulus;
      this.visible = true;
    },
    save() {
      if (this.$refs.form.validate()) {
        console.log(this.form);
      }
    },
    // Service
    async getLov(type) {
      try {
        this[`loading_${type}`] = true;
        await PublicService[`${type}GetList`]({
          search: ""
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this[`${type}List`] = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this[`loading_${type}`] = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getLov("pendidikan");
    this.getLov("studi");
  }
};
</script>
